import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ApiService} from '../services/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvnasModuleGuard implements CanActivate {

  constructor(private _auth: AuthService, private _api: ApiService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (!this._auth.loggedUser.permissions) {
      return new Observable<boolean>(observer => {
        this._api.handleRequest(this._auth.getUserPermissions(true)).subscribe(() => {
          observer.next(this._auth.checkPermission(next.data.permission));
          observer.complete();
        });
      });
    }
    return this._auth.checkPermission(next.data.permission);
  }

}
